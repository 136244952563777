import { Component } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';

import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SubmitButtonComponent } from 'src/app/shared/components/submit-button/submit-button.component';
import { ContentItemCollection } from 'src/app/shared/services/content/content.service';

@Component({
  selector: 'app-verification-sent',
  standalone: true,
  imports: [AsyncPipe, NgIf, MatIconModule, SubmitButtonComponent],
  templateUrl: './verification-sent.component.html',
  styleUrls: ['./verification-sent.component.scss']
})
export class VerificationSentComponent {
  isLoading = false;
  emailSent = false;
  onDestroy = new Subject();
  content$ = this.appState.get$<ContentItemCollection>('contentItemCollection')
    .pipe(
      filter(collection => !!collection),
      map(collection => {
        const slug = this.activatedRoute.snapshot.url[0].path;
        const email = this.activatedRoute.snapshot.queryParams.email || 'you';
        const col = collection[slug];
        col.confirmation = col.confirmation.replace('%%EMAIL%%', email);
        return col;
      })
    );


  constructor(
    private appState: AppStateService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  resend() {
    if (window.sessionStorage.getItem('APOLLO_FAMILY_REGISTRATION')) {
      const payload = JSON.parse(atob(window.sessionStorage.getItem('APOLLO_FAMILY_REGISTRATION')))
      this.isLoading = true;
      this.authService.registerFamilyMember(payload)
        .pipe(
          takeUntil(this.onDestroy),
        )
        .subscribe(res => {
          if (res.status === 'SUCCESS') {
            this.isLoading = false;
            this.emailSent = true;
          }
        })
    }
  }
}
