import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Facility, FacilityService } from '../../services/facility/facility.service';

export const facilityResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const facilityService = inject(FacilityService);
    const appState = inject(AppStateService);

    // check cache for this facility. Couldn't get `shareReplay(1)` to work
    if (!appState.get<Facility>('currentFacility')) {
      const facilityId = appState.get<string>('currentFacilityId');
      return facilityService.getFacility(facilityId);
    }
    return undefined;
  };
