import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Resident, ResidentService } from '../../services/resident/resident.service';

export const residentPreferenceResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const residentsService = inject(ResidentService);
    const appState = inject(AppStateService);

    const preferenceId = appState.get<Resident>('currentResident').preferenceId;
    if (preferenceId) {
      return residentsService.getResidentPreference(preferenceId);
    }
    return undefined;
  };
