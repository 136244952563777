import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-resident-layout',
  standalone: true,
  imports: [MatTabsModule, RouterOutlet, NgFor, RouterLinkActive, RouterLink, MatIconModule, NgIf, NgClass, AsyncPipe],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class ResidentLayoutComponent {
  isLoading = false
  isMobile$ = this.appState.isMobile$;
  activeLinkIndex = -1;
  resident$ = this.appState.get$('currentResident');
  navLinks = [
    {
      label: 'Profile',
      link: '/profile',
      color: 'primary',
      icon: 'account_circle',
      index: 0,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private appState: AppStateService,
  ) {
  }

  getClass() {

    const tab = this.navLinks.find(link => link.link === `/${this.route.parent.snapshot.url[0].path}`);
    return tab ? tab.color : '';
  }
}
