import { Component } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Subject, filter, map } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ContentItemCollection } from 'src/app/shared/services/content/content.service';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [NgIf, AsyncPipe],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  onDestroy = new Subject();
  content$ = this.appState.get$<ContentItemCollection>('contentItemCollection')
    .pipe(
      filter(collection => !!collection),
      map(collection => {
        return collection['family-logout'];
      })
    )
  constructor(
    private appState: AppStateService,
  ) {}
}
