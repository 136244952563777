import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.programmaticLogout();
    const { token } = route.queryParams;
    if (token) {
      return this.authService.programmaticLogout()
        .pipe(
          switchMap(() => this.authService.verifyToken({ token })
            .pipe(
              map(res => {
                if (res.status === 'SUCCESS') {
                  return true;
                } else {
                  return this.router.parseUrl('/expired');
                }
              })
            )
          )
        );
    }
    return false;
  }
}

