import { Component } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Subject, filter, map, tap } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ContentItemCollection } from 'src/app/shared/services/content/content.service';

@Component({
  selector: 'app-expired',
  standalone: true,
  imports: [NgIf, AsyncPipe],
  templateUrl: './expired.component.html',
})
export class ExpiredComponent {
  onDestroy = new Subject();
  content$ = this.appState.get$<ContentItemCollection>('contentItemCollection')
    .pipe(
      filter(collection => !!collection),
      map(collection => {
        return collection['magic-link-expired'];
      })
    )
  constructor(
    private appState: AppStateService,
  ) {}
}
