import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { Subject, filter, map, takeUntil } from 'rxjs';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { SubmitButtonComponent } from 'src/app/shared/components/submit-button/submit-button.component';
import { FORM_STATE } from 'src/app/shared/models/global';
import { GtmService } from 'src/app/shared/services/gtm/gtm.service';
import { ContentItemCollection } from 'src/app/shared/services/content/content.service';

export interface FamilyForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: 'app-family-register',
  standalone: true,
  imports: [SubmitButtonComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, AsyncPipe],
  templateUrl: './register.component.html',
})
export class FamilyRegisterComponent implements OnInit, OnDestroy {

  get isLoading() {
    return this.formState === FORM_STATE.LOADING;
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get email() {
    return this.form.get('email');
  }
  form: FormGroup<FamilyForm>;
  formState = FORM_STATE.RESTING;
  onDestroy = new Subject();

  content$ = this.appState.get$<ContentItemCollection>('contentItemCollection')
    .pipe(
      filter(collection => !!collection),
      map(collection => {
        console.log(this.router);
        const slug = this.activatedRoute.snapshot.url[0].path;
        console.log('>>', collection[slug]);
        return collection[slug];
      })
    );


  constructor(
    private appState: AppStateService,
    private router: Router,
    private gtmService: GtmService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });


  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  doRegister() {
    this.formState = FORM_STATE.LOADING
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.formState = FORM_STATE.RESTING;
      return;
    }

    const payload: any = this.form.getRawValue();
    payload.token = encodeURIComponent(this.activatedRoute.snapshot.queryParams.token);
    window.sessionStorage.setItem('APOLLO_FAMILY_REGISTRATION', btoa(JSON.stringify(payload)));
    this.authService.registerFamilyMember(payload)
      .pipe(
        takeUntil(this.onDestroy),
      )
      .subscribe(res => {
        if (res.status === 'SUCCESS') {
          this.gtmService.pushEvent({ event: 'family-member-registered' });
          this.router.navigate(['/verification-sent'], {
            queryParams: {
              email: this.email.value
            }
          });
        }
      })
  }

  getErrorMessages(control: AbstractControl): string {
    if (control.invalid) {
      const error = Object.keys(control.errors!)[0];
      switch (error) {
        case 'required':
          return 'This field is required';
        case 'email':
          return 'Please provide a valid email address'
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  // replaceContent(content: string) {
  //   return content.replace('%%EMAIL%%', this.email.value);
  // }
}
