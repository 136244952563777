import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenLoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // This Guard should ONLY trigger on a /auth? path and not an /auth/something? path.
    // if (state.url.search(/^\/auth\/.*/) !== -1) {
    //   return true;
    // }
    const { token } = route.queryParams;

    if (token) {
      return this.authService.programmaticLogout().pipe(
        switchMap(val => this.authService.verifyToken({ token })
          .pipe(
            switchMap(result => {
              if (result.payload) {
                const payload = JSON.parse(result.payload) as { username: string, token: string, target: string };
                return this.authService.customChallengeLogin({ challenge: payload.token, username: payload.username, removeChallenge: false })
                  .pipe(
                    map(result => {
                      if (result.status === 'SUCCESS') {
                        if (payload.target === 'RESIDENT_PROFILE') {
                          return this.router.parseUrl(`/profile`);
                        }
                      }
                      // fallback for incorrect target
                      return this.router.parseUrl('/expired');
                    })
                  )
              }
              return of(this.router.parseUrl('/expired'))
            })
          )
        ))
    } else {
      return this.router.parseUrl('/error');
    }
  }
}