<section class="flex flex-auto flex-col" *ngIf="isMobile$ | async as isMobile" [ngClass]="{ mobile: isMobile.mobile}">

  <header class="flex" *ngIf="resident$ | async as resident">
    <h1>
      {{ resident.firstName }} {{ resident.lastName }}
      <span *ngIf="resident.preferredName">({{ resident.preferredName }})</span>
    </h1>
  </header>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="{{getClass()}}">
    <a mat-tab-link *ngFor="let link of navLinks" class="{{link.color}}" [routerLink]="link.link" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive">
      <mat-icon class="mr-2" *ngIf="link.icon && rla.isActive">{{ link.icon }}</mat-icon>
      {{link.label}}
    </a>
    <div class="spacer"></div>
  </nav>

  <mat-tab-nav-panel #tabPanel class="flex flex-auto">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>

</section>