import { Routes } from '@angular/router';

import { FamilyRegisterComponent } from './modules/auth/containers/register/register.component';
import { SingleLayoutComponent } from './shared/components/single-layout/single-layout.component';
import { VerificationSentComponent } from './modules/auth/containers/verification-sent/verification-sent.component';
import { ExpiredComponent } from './modules/auth/containers/expired/expired.component';
import { LoginComponent } from './modules/auth/containers/login/login.component';
import { LogoutComponent } from './modules/auth/containers/logout/logout.component';
import { ResidentLayoutComponent } from './modules/resident-profile/containers/layout/layout.component';

import { RegisterGuard } from './modules/auth/guards/register/register';
import { TokenLoginGuard } from './modules/auth/guards/tokenLogin/tokenLogin.guard';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';

import { contentResolver } from './shared/resolvers/content/content.resolver';
import { residentResolver } from './shared/resolvers/resident/resident.resolver';
import { facilityResolver } from './shared/resolvers/facility/facility.resolver';
import { residentPreferenceCategoriesResolver } from './shared/resolvers/preferenceCategories/preferenceCategories.resolver';
import { residentPreferenceResolver } from './shared/resolvers/residentPreference/residentPreference.resolver';

import { APOLLO_USER_GROUPS } from './shared/models/user';
import { LogoutGuard } from './modules/auth/guards/logout/logout.guard';
import { AccessGuard } from './modules/auth/guards/access/access.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: SingleLayoutComponent,
    children: [
      {
        path: 'login',
        canActivate: [TokenLoginGuard],
        component: LoginComponent,
      },
      {
        path: 'logout',
        resolve: {
          content: contentResolver,
        },
        data: {
          loadContent: ['family-logout:heading', 'family-logout:body'],
        },
        component: LogoutComponent,
      },
      {
        path: 'register',
        resolve: {
          content: contentResolver,
        },
        canActivate: [RegisterGuard],
        data: {
          loadContent: ['family::register:heading', 'family::register:body', 'family::verification-sent:heading', 'family::verification-sent:body', 'family::verification-sent:confirmation'],
        },
        component: FamilyRegisterComponent,
      },
      {
        path: 'verification-sent',
        resolve: {
        content: contentResolver,
        },
        data: {
          loadContent: ['family::verification-sent:heading', 'family::verification-sent:body', 'family::verification-sent:confirmation'],
        },
        component: VerificationSentComponent,
      },
      {
        path: 'expired',
        resolve: {
          content: contentResolver,
        },
        data: {
          loadContent: ['magic-link-expired:heading', 'magic-link-expired:body'],
        },
        component: ExpiredComponent,
      },
    ]
  },
  {
    path: 'profile',
    loadComponent: () => import('./shared/components/layout/layout.component').then(m => m.LayoutComponent),
    data: {
      groups: [APOLLO_USER_GROUPS.FAMILY]
    },
    canActivate: [AuthGuard, AccessGuard],
    canActivateChild: [AccessGuard],
    resolve: {
      // the Family Instruction Dialog requires the facility data to be present
      facility: facilityResolver,
      // the resident profile pages require the resident record to be present
      resident: residentResolver,
    },
    children: [
      {
        path: '',
        resolve: {
          residentPreference: residentPreferenceResolver,
          preferenceCategories: residentPreferenceCategoriesResolver,
        },
        component: ResidentLayoutComponent,
        children: [
          {
            path: '',
            loadComponent: () => import('./modules/resident-profile/containers/profile/profile.component').then(m => m.ResidentProfileComponent),
            children: [
              {
                path: 'edit/:slug',
                loadComponent: () => import('./modules/resident-profile/containers/profile-edit/profile-edit.component').then(m => m.ResidentProfileEditComponent),
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'do-logout',
    // DUMMY COMPONENT. LOGOUT HAPPENS IN GUARD
    component: LogoutComponent,
    canActivate: [
      LogoutGuard
    ],
  },
  { path: '**', redirectTo: 'expired' }
]

