
<div class="flex flex-col" (keyup.enter)="doRegister()" *ngIf="content$ | async as content">

  <div class="flex flex-col" >
    <div class="content">
      <h1>{{ content.heading }}</h1>
      <div class="content" [innerHtml]="content.body" ></div>
    </div>
    <form [formGroup]="form" class="flex flex-col" autocomplete="off">

      <mat-form-field appearance="outline" class="mb-2">
        <mat-label>First Name</mat-label>
        <input matInput inputRef type="text" formControlName="firstName" tabindex="1"
          autocapitalize="off">
        <mat-error *ngIf="firstName.invalid">{{ getErrorMessages(firstName) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mb-2">
        <mat-label>Last Name</mat-label>
        <input matInput inputRef type="text" formControlName="lastName" tabindex="2"
          autocapitalize="off">
        <mat-error *ngIf="lastName.invalid">{{ getErrorMessages(lastName) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mb-2">
        <mat-label>Email</mat-label>
        <input matInput inputRef type="text" formControlName="email" tabindex="3"
          autocapitalize="off">
        <mat-error *ngIf="email.invalid">{{ getErrorMessages(email) }}</mat-error>
      </mat-form-field>

    </form>
  </div>

  <footer class="flex flex-col mt-2" >
    <app-submit-button label="" (onClick)="doRegister()" [isLoading]="isLoading" tabindex="3">
      <div class="flex flex-row place-content-center">
        Register
      </div>
    </app-submit-button>

  </footer>


</div>