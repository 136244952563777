import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Dictionary } from 'src/app/shared/models/global';
import { Observable, forkJoin, tap } from 'rxjs';
import { getAllFieldQueries } from 'src/app/modules/resident-profile/config/util';
import { ResidentService } from '../../services/resident/resident.service';

export const residentPreferenceCategoriesResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const residentService = inject(ResidentService);
    const appState = inject(AppStateService);

    /**
     * Get all Queries from the Resident Preferences config files and resolve those queries
     * and cache in AppState so we can consume it in our preferences and content templates
     */
    const queries = getAllFieldQueries();
    const queryData$: Dictionary<Observable<any>> = {};

    for (const [key, field] of Object.entries(queries)) {
      const fnKey = field.query as keyof ResidentService;
      queryData$[key] = residentService[fnKey](field.queryParams);
    }
    return forkJoin(queryData$).pipe(
      tap(val => appState.setState('preferenceCategories', val))
    )
  };
