import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class LogoutGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('LOGOUT', this.authService.authState);
    return this.authService.logout().pipe(
      tap(res => {
        this.router.navigate(['/logout'])
      }),
      catchError(() => {
        return this.router.navigate(['/logout']);
      })
    );

  }

}
