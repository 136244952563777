export interface Dictionary<T> {
  [key: string]: T;
}

export enum FORM_STATE {
  RESTING = 'RESTING',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ViewValue {
  viewValue: string;
  value: string;
}

export interface GenericResponse {
  status: 'SUCCESS' | 'ERROR';
  message: string;
  payload?: string;
}

export enum CategoryType {
  VIDEO = 'VIDEO',
  MUSIC = 'MUSIC',
}

export enum MusicSource {
  SEVEN_DIGITAL = 'SEVEN_DIGITAL',
  YOUTUBE = 'YOUTUBE',
  UNKNOWN = 'UNKNOWN',
}
export enum VideoSource {
  YOUTUBE = 'YOUTUBe',
  VIMEO = 'VIMEO',
}

export interface ViewValue {
  viewValue: string;
  value: string;
}
