import { Component, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet, Event } from '@angular/router';
import { registerSvgIcons } from './shared/static/svgIcons';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, LoaderComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
})
export class AppComponent implements OnDestroy {
  onDestroy = new Subject();
  loading = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {
    registerSvgIcons(this.matIconRegistry, this.domSanitizer);
    const bodyTag = document.body;
    bodyTag.classList.add('loaded');
    this.stopAppLoader();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

   /**
   * Stop the HTML Loader on navigationEnd
   */
   private stopAppLoader() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy),
        // we only want one NavigationEnd  event to filter through
        filter((event: Event) => event instanceof NavigationEnd),
        tap(data => {
          this.loading = false;
        }),
      ).subscribe();
  }
}