<div class="flex flex-col" *ngIf="content$ | async as content">
  <div class="flex-col body">
    <div class="flex place-content-center">
      <mat-icon m-b-32>mark_email_unread</mat-icon>
    </div>
    <div [innerHtml]="content.confirmation"></div>
  </div>
  <footer class="flex flex-col place-content-center mt-2" >
    <app-submit-button label="" (onClick)="resend()" [isLoading]="isLoading" tabindex="1">
      <div class="flex-row place-content-center">
        Resend verification email
      </div>
    </app-submit-button>
    <div class="notice flex flex-row items-start items-center" *ngIf="emailSent">
      <div class="mr-4">
        <mat-icon color="accent" >feedback</mat-icon>
      </div>
      <div>
        We have sent the email again to the above email address
      </div>
    </div>
  </footer>
</div>